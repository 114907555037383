import request from '@/utils/request'

//活动列表
export function activitylist(data) {
  return request({
    url: '/ServerActivity/serverActivityListPage',
    method: 'post',
    data
  })
}
//新增活动
export function save(data) {
  return request({
    url: '/ServerActivity/save',
    method: 'post',
    data
  })
}
//活动编辑
export function update(data) {
  return request({
    url: '/ServerActivity/updateActivity',
    method: 'post',
    data
  })
}
//自动匹配
export function selectMatchingActivity(data) {
  return request({
    url: '/ServerActivity/selectMatchingActivity',
    method: 'post',
    data
  })
}
//加入已选

export function insertSelectedCompany(data) {
  return request({
    url: '/ServerActivity/insertSelectedCompany',
    method: 'post',
    data
  })
}
//发布已选

export function releaseCompany(data) {
  return request({
    url: '/ServerActivity/releaseCompany',
    method: 'post',
    data
  })
}
// 把匹配出来的企业与活动移除
export function deleteCorrelation(data) {
  return request({
    url: '/ServerActivity/deleteCorrelation',
    method: 'post',
    data
  })
}
//已选企业数据一览
export function selectedCompanyPage(data) {
  return request({
    url: '/ServerActivity/selectedCompanyPage',
    method: 'post',
    data
  })
}
//活动详情

export function selectById(query) {
  return request({
    url: '/ServerActivity/selectActivityById',
    method: 'get',
    params: query
  })
}
//获取省

export function getProvince() {
  return request({
    url: '/city/getCityAll',
    method: 'get'
  })
}

//城市
export function getByFatherIdt(query) {
  return request({
    url: '/city/getAllCity',
    method: 'get',
    params: query
  })
}
//新增活动标签
export function savelabel(data) {
  return request({
    url: '/ServerActivity/insertActivityLabel',
    method: 'post',
    data
  })
}
//编辑活动标签
export function updateInformationLabel(data) {
  return request({
    url: '/ServerActivity/updateActivityLabel',
    method: 'post',
    data
  })
}
//活动标签详情
export function getBylabelId(query) {
  return request({
    url: '/ServerActivity/getById',
    method: 'get',
    params: query

  })
}

//活动报名一览
export function selectActivitySignUp(data) {
  return request({
    url: '/ServerActivity/selectActivitySignUp',
    method: 'post',
    data
  })
}
//审核拒绝-用户报名活动
export function refuseUserRegisterActivity(query) {
  return request({
    url: '/ServerActivity/refuseUserRegisterActivity',
    method: 'get',
    params: query

  })
}
//审核通过
export function auditUserRegisterActivity(query) {
  return request({
    url: '/ServerActivity/auditUserRegisterActivity',
    method: 'get',
    params: query

  })
}
//冻结活动
export function freezeActivity(query) {
  return request({
    url: '/ServerActivity/freezeActivity',
    method: 'get',
    params: query
  })
}
//激活活动
export function cancelFreezeActivity(query) {
  return request({
    url: '/ServerActivity/cancelFreezeActivity',
    method: 'get',
    params: query
  })
}
//批量设置活动类型---通用版
export function updateGeneralVersion(data) {
  return request({
    url: '/ServerActivity/updateGeneralVersion',
    method: 'post',
    data
  })
}
//批量设置活动下架
export function updateOffShelf(data) {
  return request({
    url: '/ServerActivity/updateOffShelf',
    method: 'post',
    data
  })
}
//批量设置活动上架
export function updateOnShelf(data) {
  return request({
    url: '/ServerActivity/updateOnShelf',
    method: 'post',
    data
  })
}
//活动报名用户签到
export function userSignIn(query) {
  return request({
    url: '/ServerActivity/userSignIn',
    method: 'get',
    params: query
  })
}
//用户提交报名信息
export function userPushRegister(data) {
  return request({
    url: '/wechatCompanyActivity/userPushRegister',
    method: 'post',
    data
  })
}
//批量导出活动用户报名数据
export function exportExcel(data) {
  return request({
    url: '/activityForm/export',
    method: 'post',
    responseType: "blob",
    data
  })
}
//活动配置智参中心数据一览
export function wisdomGinsengReportPage(data) {
  return request({
    url: '/ServerActivity/wisdomGinsengActivityPage',
    method: 'post',
    data
  })
}
//报告配置智参中心下架
export function wisdomGinsengOffSelf(data) {
  return request({
    url: '/ServerActivity/wisdomGinsengOffSelf',
    method: 'post',
    data
  })
}
//报告配置智参中心上架
export function wisdomGinsengOnSelf(data) {
  return request({
    url: '/ServerActivity/wisdomGinsengOnSelf',
    method: 'post',
    data
  })
}
//批量设置活动类型---通用版 (配置智参中心)
export function updateGeneralVersions(data) {
  return request({
    url: '/ServerActivity/updateGeneralVersions',
    method: 'post',
    data
  })
}
//根据请求路径生成二维码
export function getQRCode(query) {
  return request({
    url: '/ServerActivity/basicQrcode',
    method: 'get',
    params: query
  })
}


// 活动表单详情
export function selectActivityFormById(query) {
  return request({
    url: '/activityForm/selectActivityFormById',
    method: 'get',
    params: query
  })
}

// 新增活动表单
export function insertActivityForm(data) {
  return request({
    url: '/activityForm/insertForm',
    method: 'post',
    data
  })
}

// 新增活动表单
export function updateActivityForm(data) {
  return request({
    url: '/activityForm/updateForm',
    method: 'post',
    data
  })
}
//查看用户报名表单的信息
export function selectByActivityId(query) {
  return request({
    url: '/activityForm/selectByActivityId',
    method: 'get',
    params: query
  })
}
//活动查看人数一览
export function listUserCheckPage(data) {
  return request({
    url: '/ServerActivity/listUserCheckPage',
    method: 'post',
    data
  })
}
//活动分享人数一览
export function listUserSharePage(data) {
  return request({
    url: '/ServerActivity/listUserSharePage',
    method: 'post',
    data
  })
}
//已推送企业数据一览-活动
export function pushedCompanyPageActivity(data) {
  return request({
    url: '/ServerActivity/pushedCompanyPageActivity',
    method: 'post',
    data
  })
}
//手动配置企业
export function artificialMatchingActivity(data) {
  return request({
    url: '/ServerActivity/artificialMatchingActivity',
    method: 'post',
    data
  })
}

//校验活动名称是否存在
export function checkActivityName(data) {
  return request({
    url: '/ServerActivity/checkActivityName',
    method: 'get',
    params: data
  })
}

// 查询活动名称  /ServerActivity/getSystemDataParam
export function getSystemDataParam(data) {
  return request({
    url: '/ServerActivity/getSystemDataParam',
    method: 'get',
    params: data
  })
}
